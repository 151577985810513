import { useRoute } from "@react-navigation/native";
import Loading from "@src/components/loading";
import { useAuthService } from "@src/ducks/hooks";
import { parseJwt } from "@src/utils/transform-helper";
import { gtag } from "ga-gtag";
import React from "react";

const SignInVerification = () => {
  const route = useRoute<any>();
  const { onLogin, onValidateOTPSuccess } = useAuthService();

  React.useEffect(() => {
    const params = parseJwt(route.params.accessToken);
    onValidateOTPSuccess({ valid: true } as any)
    onLogin({
      ...params.user,
      ...route?.params,
      loginType: "Login via Google"
    });
    gtag("event", "login", { "method": "Login via Email" });
  }, []);

  return <Loading />;
};

export default SignInVerification;
