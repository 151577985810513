import { useNavigation } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import { useAuthService } from "@src/ducks/hooks";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import ErrorView from "./error";
import useStyles from "./styles.css";
import { LoginInput } from "@src/ducks/types";

interface ErrorProps {
  verificationCode: string;
}

const ErrorValue = {
  verificationCode: "",
};

const ResetPassword = () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const errorRef = React.useRef<any>(null);
  const { isLoading, generatedOTP, onValidateOTP, onResendOTP, onLogout, onSignin: onReSignin} = useAuthService();
  const [error, setError] = useState<ErrorProps>(ErrorValue);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [resendDisabled, setResendDisabled] = useState(true)

  const durationInSeconds = 180;
  const [timeLeft, setTimeLeft] = useState(durationInSeconds);
  const [timerRunning, setTimerRunning] = useState(true);

  const onGoBack = () => {
    setTimeout(() => navigation.goBack(), 500);
    setError({} as ErrorProps);
    errorRef.current?.onClear();
    onLogout()
  }
  const onChangeInput = (value: any) => {
    setVerificationCode(value);
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  };

  const onVerifyOTP = () => {
    const newError: any = {};

    if (!verificationCode) {
      newError.verificationCode = "Verification code is required.";
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onValidateOTP({
        code: verificationCode
      })
    }
  };

  const onResend = () => {
    setTimerRunning(true)
    setVerificationCode("");
    setError({} as ErrorProps);
    errorRef.current?.onClear();
    setResendDisabled(true)
    // onResendOTP()
    if (generatedOTP) {
      onReSignin({
        email: generatedOTP.email,
        password: generatedOTP.password
      } as LoginInput)
    }
    setTimeLeft(durationInSeconds);
  }

  let interval: any;
  useEffect(() => {
    if (timerRunning && generatedOTP?.createdAt) {
      const onTick = () => {
        // Calculate the remaining time
        const targetTime = generatedOTP?.createdAt ?? 0
        const currentTime = new Date().getTime();
        const remainingTime = Math.max(targetTime - currentTime, 0);
        return remainingTime
      }
      let shouldDisplay = false
      const remainingTime = onTick()
      if (remainingTime > 0) {
        shouldDisplay = true
        setTimeLeft(Math.floor(remainingTime / 1000));
      }

      interval = setInterval(() => {
        const remainingTime = onTick()
        const timeTick = Math.floor(remainingTime / 1000) 
        if (shouldDisplay) {
          setTimeLeft(timeTick);
        }
        setResendDisabled(!(remainingTime <= 0 && !isLoading))

        if (remainingTime <= 0 && !isLoading) {
          clearInterval(interval);
          setTimerRunning(false);
        }
        shouldDisplay = true
      }, 1000);
    }

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [timerRunning, generatedOTP]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <View style={styles.v_login}>
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>OTP Verification</Text>
        <Text style={styles.txtNote2}>
          {"We've sent a verification code to your email" + `${generatedOTP.email ? ` - ${generatedOTP.email}` : ""}`}
        </Text>
      </View>
      <ErrorView ref={errorRef} />
      <View style={styles.v_field}>
        <TextInput02
          value={verificationCode}
          label="Enter verification code"
          error={error.verificationCode}
          onChangeText={onChangeInput}
          placeholder="⬤⬤⬤⬤"
        />
        <Button01
          label="Verify"
          color={"white"}
          isLoading={isLoading}
          onPress={onVerifyOTP}
          style={[styles.btn_signin]}
          labelStyle={styles.lbl_signin}
        />
        <View style={styles.v_signin_actions}>
          <Button01
            label="Go Back"
            color={"white"}
            isLoading={isLoading}
            onPress={onGoBack}
            style={[styles.btn_back]}
            labelStyle={styles.lbl_back}
          />
          <View>
            <Button01
              label="Resend OTP"
              color={"white"}
              isLoading={isLoading}
              onPress={resendDisabled ? () => null : onResend}
              style={[styles.btn_resend]}
              labelStyle={resendDisabled ? styles.lbl_resend_disabled : styles.lbl_resend}
            />
            {resendDisabled && !isLoading &&
              <Text style={[styles.txtNote2, { fontWeight: 'bold', marginTop: 0 }]}>
                {formatTime(timeLeft)}
              </Text>
            }
          </View>
         
        </View>
        <View style={styles.v_height} />
      </View>
    </View>
  );
};

export default ResetPassword;
