import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
  authActions,
  selectAuthGeneratedOTP,
  selectAuthLogInFailed,
  selectAuthLoggedIn,
  selectAuthLoggingIn,
  selectAuthLoginInput,
  selectAuthSession,
  selectAuthValidatedOTP,
  selectedAuthAccessToken,
  selectedAuthUserID,
} from "../slices/auth.slice";

// Types
import { ErrorValue, GeneratedOTP, LoginInput, TypeValue, ValidatedOTP, loginInputValue } from "../types";

export type AuthServiceOperators = {
  validatedOTP: ValidatedOTP;
  generatedOTP: GeneratedOTP;
  failed: ErrorValue;
  userId: string;
  accessToken: string;
  isLoading: boolean;
  isLoggedIn: boolean;
  loginInput: loginInputValue;
  setLoginInput: (params: TypeValue) => void;
  onLogin: (params: any) => void;
  onLogout: () => void;
  onSignin: (params: LoginInput) => void;
  onRefreshToken: () => void;
  onResetLoading: () => void;
  onFaceIDLogin: () => void;
  onMicrosoftLogin: (params : any) => void;
  onResendOTP: () => void;
  onValidateOTP: (params: GeneratedOTP) => void;
  onValidateOTPSuccess: (params: any) => void;
};

export const useAuthService = (): Readonly<AuthServiceOperators> => {
  const dispatch = useAppDispatch();
  const session = useAppSelector(selectAuthSession);

  return {
    generatedOTP: useAppSelector(selectAuthGeneratedOTP),
    validatedOTP: useAppSelector(selectAuthValidatedOTP),
    failed: useAppSelector(selectAuthLogInFailed),
    isLoading: useAppSelector(selectAuthLoggingIn),
    isLoggedIn: useAppSelector(selectAuthLoggedIn),
    userId: useAppSelector(selectedAuthUserID),
    accessToken: useAppSelector(selectedAuthAccessToken),
    loginInput: useAppSelector(selectAuthLoginInput),
    setLoginInput: useCallback(
      (params: TypeValue) => {
        dispatch(authActions.setLoginInput(params));
      }, [dispatch]
    ),
    onLogin: useCallback(
      (params: any) => {
        dispatch(authActions.loginSuccess(params));
      }, [dispatch]
    ),
    onFaceIDLogin: useCallback(() => {
      dispatch(authActions.faceIDloginRequest());
    },[dispatch]),
    onSignin: useCallback(
      (params: LoginInput) => {
        dispatch(authActions.loginRequest(params));
      },
      [dispatch]
    ),
    onLogout: useCallback(() => {
      dispatch(authActions.logout());
    }, [dispatch]),
    onRefreshToken: useCallback(() => {
      dispatch(authActions.refreshTokenRequest(session));
    }, [dispatch, session]),
    onResetLoading: useCallback(() => {
      dispatch(authActions.resetLoading());
    }, [dispatch]),
    onMicrosoftLogin : useCallback((params: any) => {
      dispatch(authActions.microsoftLoginRequest(params));
    },[dispatch]),
    onResendOTP: useCallback(() => {
      dispatch(authActions.generateOTP());
    }, [dispatch]),
    onValidateOTP: useCallback((params: GeneratedOTP) => {
      dispatch(authActions.validateOTP(params));
    }, [dispatch]),
    onValidateOTPSuccess: useCallback((params: any) => {
      dispatch(authActions.validateOTPSuccess(params));
    }, [dispatch]),
  };
};

export default useAuthService;
